<template>
  <div class="winers main__margin-top">
    <div class="winers__title">     
        <h1>Победители этой недели</h1>    
    </div>
    <div class="winers__all">
      <div class="winers__all--marq" :style="`animation: marquee ${mas.length * 1.8}s linear infinite;`">
        <div class="winers__all--block" v-for="item in mas.concat(mas)" :key="item.id">
            <div class="winers__all--title"> {{item.name}}  </div>
            <div class="winers__all--phone"> {{item.phone}} </div>
        </div>    
      </div>   
    </div>
    <a href="https://www.delivery-club.ru/promo_rules/%D0%9F%D0%BE%D0%B1%D0%B5%D0%B4%D0%B8%D1%82%D0%B5%D0%BB%D0%B8%20%D1%82%D1%80%D0%B5%D1%82%D1%8C%D0%B5%D0%B8%CC%86%20%D0%BD%D0%B5%D0%B4%D0%B5%D0%BB%D0%B8%20%D0%B0%D0%BA%D1%86%D0%B8%D0%B8.pdf?1628082877" target="_blank" class="winers__vis"> Посмотреть всех </a>
  </div>
</template>

<script>
import style from './winers-style.scss';



export default {
  name: 'Winers',
  props: [],
  data(){
    return{
      mas: [{
        id: 1,
        name: 'Ольга',
        phone: '+7(903) *** *8 95'
      },
      {
        id: 2,
        name: 'Алексей',
        phone: '+7(911) *** *8 68'
      },
      {
        id: 3,
        name: 'Иван',
        phone: '+7(911) *** *3 98'
      },
      {
        id: 4,
        name: 'Роман',
        phone: '+7(913) *** *3 95'
      },
      {
        id: 5,
        name: 'Сергей',
        phone: '+7(914) *** *9 19'
      },
      {
        id: 6,
        name: 'Марк',
        phone: '+7(918) *** *3 98'
      },
      {
        id: 7,
        name: 'Юлия',
        phone: '+7(920) *** *9 99'
      },
      {
        id: 8,
        name: 'Елена',
        phone: '+7(924) *** *8 80'
      },
      {
        id: 9,
        name: 'Павел',
        phone: '+7(926) *** *8 79'
      },
      {
        id: 10,
        name: 'Мария',
        phone: '+7(926) *** *3 04'
      },
      {
        id: 11,
        name: 'Сергей',
        phone: '+7(926) *** *1 11'
      },
      {
        id: 12,
        name: 'Юлия',
        phone: '+7(927) *** *2 98'
      },
      {
        id: 13,
        name: 'Вика',
        phone: '+7(930) *** *5 45'
      },
      {
        id: 14,
        name: 'Антон',
        phone: '+7(931) *** *7 55'
      },
      {
        id: 15,
        name: 'Данис',
        phone: '+7(937) *** *2 66'
      },
      {
        id: 16,
        name: 'Александр',
        phone: '+7(950) *** *1 55'
      },
      {
        id: 17,
        name: 'Олеся',
        phone: '+7(950) *** *5 44'
      },
      {
        id: 18,
        name: 'Ришат',
        phone: '+7(962) *** *6 85'
      },
      {
        id: 19,
        name: 'Александр',
        phone: '+7(963) *** *8 02'
      },
      {
        id: 20,
        name: 'Никита',
        phone: '+7(966) *** *6 66'
      },
      {
        id: 21,
        name: 'Гульнара',
        phone: '+7(967) *** *0 81'
      },
      {
        id: 22,
        name: 'Кирилл',
        phone: '+7(968) *** *1 96'
      },
      {
        id: 23,
        name: 'Вероника',
        phone: '+7(977) *** *5 12'
      },
      {
        id: 24,
        name: 'Татьяна',
        phone: '+7(980) *** *7 64'
      },
      {
        id: 25,
        name: 'Диана',
        phone: '+7(981) *** *6 03'
      },
      {
        id: 26,
        name: 'Ольга',
        phone: '+7(985) *** *8 47'
      },
      {
        id: 27,
        name: 'Анна',
        phone: '+7(985) *** *5 44'
      },
      {
        id: 28,
        name: 'Татьяна',
        phone: '+7(985) *** *4 26'
      },
      {
        id: 29,
        name: 'Полина',
        phone: '+7(988) *** *9 63'
      },
      {
        id: 30,
        name: 'Константин',
        phone: '+7(999) *** *2 10'
      },
      {
        id: 31,
        name: 'Светлана',
        phone: '+7(903) *** *1 08'
      },
      {
        id: 32,
        name: 'Аделя',
        phone: '+7(903) *** *0 09'
      },
      {
        id: 33,
        name: 'Сергей',
        phone: '+7(903) *** *0 56'
      },
      {
        id: 34,
        name: 'Анастасия',
        phone: '+7(906) *** *1 67'
      },
      {
        id: 35,
        name: 'Юля',
        phone: '+7(910) *** *3 31'
      },
      {
        id: 36,
        name: 'Кристина',
        phone: '+7(911) *** *0 07'
      },
      {
        id: 37,
        name: 'Екатерина',
        phone: '+7(915) *** *7 25'
      },
      {
        id: 38,
        name: 'Татьяна',
        phone: '+7(916) *** *9 06'
      },
      {
        id: 39,
        name: 'Борис',
        phone: '+7(919) *** *6 40'
      },
      {
        id: 40,
        name: 'Антон',
        phone: '+7(938) *** *7 22'
      },
      {
        id: 41,
        name: 'Евгения',
        phone: '+7(953) *** *1 54'
      },
      {
        id: 42,
        name: 'Марина',
        phone: '+7(980) *** *2 22'
      },
      {
        id: 43,
        name: 'Виталий',
        phone: '+7(995) *** *1 75'
      },
      {
        id: 44,
        name: 'Анна',
        phone: '+7(999) *** *2 32'
      },
      {
        id: 45,
        name: 'Александр',
        phone: '+7(999) *** *0 80'
      },
      {
        id: 46,
        name: 'Тая',
        phone: '+7(905) *** *1 05'
      },
      {
        id: 47,
        name: 'Юлия',
        phone: '+7(916) *** *5 90'
      },
      {
        id: 48,
        name: 'Владимир',
        phone: '+7(926) *** *0 50'
      },
      {
        id: 49,
        name: 'Андрей',
        phone: '+7(985) *** *3 05'
      },
      {
        id: 50,
        name: 'Петр',
        phone: '+7(988) *** *4 57'
      },
      {
        id: 51,
        name: 'Алексей',
        phone: '+7(915) *** *4 84'
      },
      {
        id: 52,
        name: 'Владимир',
        phone: '+7(916) *** *2 91'
      },
      {
        id: 53,
        name: 'Екатерина',
        phone: '+7(960) *** *8 70'
      },
      {
        id: 54,
        name: 'Валерий',
        phone: '+7(985) *** *2 60'
      },
      {
        id: 55,
        name: 'Сергей',
        phone: '+7(903) *** *6 41'
      },
      {
        id: 56,
        name: 'Ани',
        phone: '+7(903) *** *4 46'
      },
      {
        id: 57,
        name: 'Андрей',
        phone: '+7(903) *** *4 69'
      },
      {
        id: 58,
        name: 'Александра',
        phone: '+7(905) *** *9 64'
      },
      {
        id: 59,
        name: 'Ринат',
        phone: '+7(906) *** *5 80'
      },
      {
        id: 60,
        name: 'Лиза',
        phone: '+7(913) *** *7 83'
      },
      {
        id: 61,
        name: 'Наталья',
        phone: '+7(920) *** *4 18'
      },
      {
        id: 62,
        name: 'Евгения',
        phone: '+7(920) *** *5 15'
      },
      {
        id: 63,
        name: 'Евгений',
        phone: '+7(921) *** *5 08'
      },
      {
        id: 64,
        name: 'Софья',
        phone: '+7(926) *** *4 51'
      },
      {
        id: 65,
        name: 'Глеб',
        phone: '+7(911) *** *2 52'
      },
      {
        id: 66,
        name: 'Маргарита',
        phone: '+7(918) *** *6 31'
      },
      {
        id: 67,
        name: 'Александр',
        phone: '+7(927) *** *8 67'
      },
      {
        id: 68,
        name: 'Анастасия',
        phone: '+7(977) *** *8 77'
      },
      {
        id: 69,
        name: 'Анна',
        phone: '+7(900) *** *8 04'
      },
      {
        id: 70,
        name: 'Андрей',
        phone: '+7(916) *** *1 44'
      },
      {
        id: 71,
        name: 'Екатерина',
        phone: '+7(923) *** *2 60'
      },
      {
        id: 72,
        name: 'Ольга',
        phone: '+7(969) *** *6 98'
      },
      {
        id: 73,
        name: 'Надежда',
        phone: '+7(965) *** *9 43'
      },
      {
        id: 74,
        name: 'Газим',
        phone: '+7(938) *** *0 87'
      },
      {
        id: 75,
        name: 'Виктория',
        phone: '+7(929) *** *5 87'
      },
      {
        id: 76,
        name: 'Тимур',
        phone: '+7(915) *** *4 70'
      },
      {
        id: 77,
        name: 'Александр',
        phone: '+7(961) *** *9 86'
      },
      {
        id: 78,
        name: 'Ирина',
        phone: '+7(903) *** *7 47'
      },
      {
        id: 79,
        name: 'Анастасия',
        phone: '+7(919) *** *1 54'
      },
      {
        id: 80,
        name: 'Евгений',
        phone: '+7(977) *** *8 87'
      },
      {
        id: 81,
        name: 'Антон',
        phone: '+7(919) *** *4 12'
      },
      {
        id: 82,
        name: 'Николай',
        phone: '+7(995) *** *0 92'
      },
      {
        id: 83,
        name: 'Константин',
        phone: '+7(999) *** *5 01'
      },
      {
        id: 84,
        name: 'Ольга',
        phone: '+7(919) *** *1 00'
      },
      {
        id: 85,
        name: 'Дарья',
        phone: '+7(969) *** *7 55'
      },
      {
        id: 86,
        name: 'Оксана',
        phone: '+7(915) *** *0 49'
      },
      {
        id: 87,
        name: 'Марианна',
        phone: '+7(910) *** *3 95'
      },
      {
        id: 88,
        name: 'Елизавета',
        phone: '+7(901) *** *5 27'
      },
      {
        id: 88,
        name: 'Елизавета',
        phone: '+7(901) *** *5 27'
      },
      {
        id: 89,
        name: 'Александр',
        phone: '+7(963) *** *8 83'
      },
      {
        id: 90,
        name: 'Кристина',
        phone: '+7(923) *** *5 53'
      },
      {
        id: 91,
        name: 'Дмитрий',
        phone: '+7(962) *** *2 07'
      },
      {
        id: 92,
        name: 'Алексей',
        phone: '+7(977) *** *1 07'
      },
      {
        id: 93,
        name: 'Антонина',
        phone: '+7(967) *** *0 29'
      },
      {
        id: 94,
        name: 'Анастасия',
        phone: '+7(977) *** *6 23'
      },
      {
        id: 95,
        name: 'Андрей',
        phone: '+7(919) *** *1 60'
      },
      {
        id: 96,
        name: 'Ирина',
        phone: '+7(967) *** *7 03'
      },
      {
        id: 97,
        name: 'Карина',
        phone: '+7(989) *** *9 78'
      },
      {
        id: 98,
        name: 'Светлана',
        phone: '+7(981) *** *7 55'
      },
      {
        id: 99,
        name: 'Людмила',
        phone: '+7(915) *** *7 65'
      },
      {
        id: 100,
        name: 'Александра',
        phone: '+7(930) *** *8 85'
      },
      {
        id: 101,
        name: 'Яна',
        phone: '+7(925) *** *1 05'
      },
      {
        id: 102,
        name: 'Михаил',
        phone: '+7(985) *** *7 29'
      },
      {
        id: 103,
        name: 'Игорь',
        phone: '+7(985) *** *1 85'
      },
      {
        id: 104,
        name: 'Иван',
        phone: '+7(967) *** *1 42'
      },
      {
        id: 105,
        name: 'Максим',
        phone: '+7(999) *** *9 32'
      },
      {
        id: 106,
        name: 'Александр',
        phone: '+7(965) *** *4 93'
      },
      {
        id: 107,
        name: 'Борис',
        phone: '+7(917) *** *6 26'
      },
      {
        id: 108,
        name: 'Дмитрий',
        phone: '+7(905) *** *5 25'
      },
      {
        id: 109,
        name: 'Светлана',
        phone: '+7(904) *** *1 09'
      },
      {
        id: 110,
        name: 'Елизавета',
        phone: '+7(963) *** *9 49'
      },
      {
        id: 111,
        name: 'Вячеслав',
        phone: '+7(962) *** *0 34'
      },
      {
        id: 112,
        name: 'Саша',
        phone: '+7(901) *** *6 71'
      },
      {
        id: 113,
        name: 'Победитель 4',
        phone: '+7(987) *** *0 66'
      },
      {
        id: 114,
        name: 'Сергей',
        phone: '+7(916) *** *9 20'
      },
      {
        id: 115,
        name: 'Александра',
        phone: '+7(951) *** *9 29'
      },
      {
        id: 116,
        name: 'Максим',
        phone: '+7(922) *** *3 93'
      },
      {
        id: 117,
        name: 'Марина',
        phone: '+7(925) *** *0 88'
      },
      {
        id: 118,
        name: 'Мария',
        phone: '+7(953) *** *0 91'
      },
      {
        id: 119,
        name: 'Руслан',
        phone: '+7(964) *** *0 09'
      },
      {
        id: 120,
        name: 'Иван',
        phone: '+7(960) *** *5 88'
      },
      {
        id: 121,
        name: 'Антон',
        phone: '+7(900) *** *8 43'
      },
      {
        id: 122,
        name: 'Вера',
        phone: '+7(977) *** *1 31'
      },
      {
        id: 123,
        name: 'Кристина',
        phone: '+7(929) *** *6 47'
      },
      {
        id: 124,
        name: 'Марина',
        phone: '+7(916) *** *9 25'
      },
      {
        id: 125,
        name: 'Юлия',
        phone: '+7(905) *** *5 62'
      },
      {
        id: 126,
        name: 'Максим',
        phone: '+7(996) *** *9 42'
      },
      {
        id: 127,
        name: 'Полина',
        phone: '+7(965) *** *0 06'
      },
      {
        id: 128,
        name: 'Олег',
        phone: '+7(929) *** *3 63'
      },
      {
        id: 129,
        name: 'Ирина',
        phone: '+7(916) *** *2 42'
      },
      {
        id: 130,
        name: 'Ирина',
        phone: '+7(985) *** *8 63'
      },
      {
        id: 131,
        name: 'Александр',
        phone: '+7(953) *** *1 00'
      },
      {
        id: 132,
        name: 'Алла',
        phone: '+7(929) *** *2 98'
      },
      {
        id: 133,
        name: 'Ольга',
        phone: '+7(983) *** *1 61'
      },
      {
        id: 134,
        name: 'Игорь',
        phone: '+7(952) *** *1 40'
      },
      {
        id: 135,
        name: 'Лидия',
        phone: '+7(962) *** *9 59'
      },
      {
        id: 136,
        name: 'Алёна',
        phone: '+7(928) *** *9 33'
      },
      {
        id: 137,
        name: 'Павел',
        phone: '+7(985) *** *2 10'
      },
      {
        id: 138,
        name: 'Мария',
        phone: '+7(960) *** *0 20'
      },
      {
        id: 139,
        name: 'Мария',
        phone: '+7(927) *** *7 85'
      },
      {
        id: 140,
        name: 'Владислав',
        phone: '+7(903) *** *9 02'
      },
      {
        id: 141,
        name: 'Виктория',
        phone: '+7(989) *** *0 72'
      },
      {
        id: 142,
        name: 'Мария',
        phone: '+7(905) *** *7 85'
      },
      {
        id: 143,
        name: 'Натэлла',
        phone: '+7(925) *** *5 67'
      },
      {
        id: 144,
        name: 'Елена',
        phone: '+7(926) *** *4 95'
      },
      {
        id: 145,
        name: 'Вячеслав',
        phone: '+7(902) *** *7 63'
      },
      {
        id: 146,
        name: 'Сабина',
        phone: '+7(925) *** *3 83'
      },
      {
        id: 147,
        name: 'Нина',
        phone: '+7(917) *** *0 26'
      },
      {
        id: 148,
        name: 'Татьяна',
        phone: '+7(960) *** *8 97'
      },
      {
        id: 149,
        name: 'Виктор',
        phone: '+7(977) *** *5 39'
      },
      {
        id: 150,
        name: 'Надежда',
        phone: '+7(910) *** *8 04'
      },
      {
        id: 151,
        name: 'Кирилл',
        phone: '+7(931) *** *7 12'
      },
      {
        id: 152,
        name: 'Дмитрий',
        phone: '+7(964) *** *6 44'
      },
      {
        id: 153,
        name: 'Гульноза',
        phone: '+7(965) *** *9 05'
      },
      {
        id: 154,
        name: 'Денис',
        phone: '+7(920) *** *0 06'
      },
      {
        id: 155,
        name: 'Алина',
        phone: '+7(904) *** *8 85'
      },
      {
        id: 156,
        name: 'Екатерина',
        phone: '+7(919) *** *2 86'
      },
      {
        id: 157,
        name: 'Елизавета',
        phone: '+7(901) *** *1 89'
      },
      {
        id: 158,
        name: 'Анастасия',
        phone: '+7(981) *** *0 53'
      },
      {
        id: 159,
        name: 'Ольга',
        phone: '+7(916) *** *2 03'
      },
      {
        id: 160,
        name: 'Куаныш',
        phone: '+7(988) *** *7 03'
      },
      {
        id: 161,
        name: 'Арсений',
        phone: '+7(915) *** *1 85'
      },
      {
        id: 162,
        name: 'Анна',
        phone: '+7(962) *** *2 89'
      },
      {
        id: 163,
        name: 'Надежда',
        phone: '+7(929) *** *5 84'
      },
      {
        id: 164,
        name: 'Татьяна',
        phone: '+7(901) *** *3 64'
      },
      {
        id: 165,
        name: 'Натали',
        phone: '+7(917) *** *6 88'
      },
      {
        id: 166,
        name: 'Павел',
        phone: '+7(952) *** *6 38'
      },
      {
        id: 167,
        name: 'Николай',
        phone: '+7(923) *** *8 85'
      },
      {
        id: 168,
        name: 'Алиса',
        phone: '+7(926) *** *3 66'
      },
      {
        id: 169,
        name: 'Ольга',
        phone: '+7(909) *** *7 03'
      },
      {
        id: 170,
        name: 'Светлана',
        phone: '+7(960) *** *3 85'
      },
      {
        id: 171,
        name: 'Евгения',
        phone: '+7(921) *** *8 44'
      },
      {
        id: 172,
        name: 'Ксения',
        phone: '+7(968) *** *9 80'
      },
      {
        id: 173,
        name: 'Виктор',
        phone: '+7(923) *** *1 33'
      },
      {
        id: 174,
        name: 'Дина',
        phone: '+7(903) *** *5 74'
      },
      {
        id: 175,
        name: 'Никита',
        phone: '+7(968) *** *7 66'
      },
      {
        id: 176,
        name: 'Юлия',
        phone: '+7(977) *** *1 79'
      },
      {
        id: 177,
        name: 'Андрей',
        phone: '+7(909) *** *2 99'
      },
      {
        id: 178,
        name: 'Инесса',
        phone: '+7(905) *** *2 52'
      },
      {
        id: 179,
        name: 'Анастасия',
        phone: '+7(915) *** *8 19'
      },
      {
        id: 180,
        name: 'Илья',
        phone: '+7(925) *** *8 62'
      },
      {
        id: 181,
        name: 'Анастасия',
        phone: '+7(982) *** *6 15'
      },
      {
        id: 182,
        name: 'София',
        phone: '+7(999) *** *2 12'
      }
      
      
      ]
    }
  },
  mounted(){
    
  },
  computed: {
    publicPath(){
      return window.location.origin
    }
  },
  components: {
 
  }
}
</script>
